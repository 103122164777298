import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { dateUtils } from 'utils/time';

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  labels: {
    label: string;
    value: string;
  }[];
}

export function FrequencyGraphToolTip({ active, payload, labels }: CustomTooltipProps) {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload;

  return (
    <div className="bg-white rounded-lg px-4 py-2 border-light border-border-primary flex flex-col gap-1.5">
      <span className="text-2xs text-light-grey mb-2 text-start">
        {dateUtils.format(data?.date || '', 'MMM D YYYY').toString()}
      </span>
      {labels?.map((label) => (
        <div key={label.label} className="flex items-center justify-between gap-8">
          <span className="text-xs">{label.label}</span>
          <span className="text-xs">{data?.[label.value]}</span>
        </div>
      ))}
    </div>
  );
}
