import { useState } from 'react';

import { Event } from 'models/events';

import {
  getThreatDetailsByCode,
  getInferenceDetailsByCode,
  inferenceToProductMap,
  getInferences,
} from './data';
import { AnalysisBadge } from './AnalysisBadge';

interface EmailAnalysisProps {
  event: Event;
}

export function EmailAnalysis({ event }: EmailAnalysisProps) {
  const [isStretched, setIsStretched] = useState(false);

  const handleStretch = () => {
    setIsStretched((prev) => !prev);
  };

  const MAX_DISPLAYED_BADGES = 3;

  const [expandedBadges, setExpandedBadges] = useState(false);

  const getAllInferences = () => {
    let allInferences: string[] = [];
    ['sender', 'recipients', 'attachments', 'links'].forEach((key) => {
      const inferences = getInferences(key, event);
      allInferences = allInferences.concat(inferences);
    });
    return allInferences;
  };

  const getAllBadges: () => React.ReactNode[] = () => {
    const badges: React.ReactNode[] = [];

    if (event?.threats) {
      event.threats?.forEach((threat) => {
        const threatDetails = getThreatDetailsByCode(threat.code);
        badges.push(<AnalysisBadge text={threatDetails.text} type="detection" />);
      });

      getAllInferences().forEach((inference) => {
        const threatDetails = getInferenceDetailsByCode(inference);
        badges.push(
          <AnalysisBadge
            text={threatDetails.text}
            type={inferenceToProductMap[inference] || 'others'}
          />
        );
      });
    } else {
      event?.detections?.forEach((detection) => {
        const threatDetails = getThreatDetailsByCode(detection);
        badges.push(<AnalysisBadge text={threatDetails.text} type="detection" />);
      });

      event?.inferences?.forEach((inference) => {
        const threatDetails = getInferenceDetailsByCode(inference);
        badges.push(
          <AnalysisBadge
            text={threatDetails.text}
            type={inferenceToProductMap[inference] || 'others'}
          />
        );
      });
    }

    event.tone?.forEach((tone) => {
      badges.push(<AnalysisBadge text={tone} type="tone" />);
    });

    event.intent?.forEach((intent) => {
      badges.push(<AnalysisBadge text={intent} type="tone" />);
    });

    return badges;
  };

  const badges = getAllBadges();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <span>Analysis</span>
        <button
          type="button"
          className="bg-select-background text-light-grey text-2xs px-1.5 py-0.5 rounded"
        >
          BETA
        </button>
      </div>
      <div className="flex flex-wrap gap-2">
        {badges.slice(0, MAX_DISPLAYED_BADGES).map((badge) => badge)}
        {badges.length > MAX_DISPLAYED_BADGES && expandedBadges && (
          <>{badges.slice(MAX_DISPLAYED_BADGES).map((badge) => badge)}</>
        )}
        {badges.length > MAX_DISPLAYED_BADGES ? (
          <AnalysisBadge
            text={expandedBadges ? 'Hide' : `+ ${badges.length - MAX_DISPLAYED_BADGES}`}
            type="others"
            onClick={() => setExpandedBadges((prevExpanded) => !prevExpanded)}
          />
        ) : null}
      </div>
      <div className="relative">
        <div className={`text-xs ${isStretched ? 'h-auto' : 'h-8'} overflow-hidden font-light`}>
          {event.reason}
          {!isStretched && (
            <div className="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-t from-white to-transparent" />
          )}
        </div>
      </div>
      <button
        type="button"
        className="rounded-full text-black px-3 py-1 border-light border-border-primary w-fit text-2xs"
        onClick={() => handleStretch()}
      >
        {isStretched ? 'Hide' : 'Show'}
      </button>
    </div>
  );
}
