import { ReactComponent as IconSettings } from 'assets/icons/settings.svg';
import { ReactComponent as RavenGrayLogo } from 'assets/logo/gray.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as HomeSelected } from 'assets/icons/home-selected.svg';
import { ReactComponent as Users } from 'assets/icons/users.svg';
import { ReactComponent as UsersSelected } from 'assets/icons/users-selected.svg';
import { ReactComponent as Analytics } from 'assets/icons/analytics.svg';
import { ReactComponent as AnalyticsSelected } from 'assets/icons/analytics-selected.svg';
// import { ReactComponent as Policy } from 'assets/icons/policies.svg';
// import { ReactComponent as PolicySelected } from 'assets/icons/policies-selected.svg';
import { ReactComponent as SettingsSelected } from 'assets/icons/settings-selected.svg';
import { ReactComponent as Logs } from 'assets/icons/logs.svg';
import { ReactComponent as LogsSelected } from 'assets/icons/logs-selected.svg';

import { Divider } from 'components/Divider';

import { Link, useLocation } from 'react-router-dom';
import { useOrgId } from 'hooks/useOrgId';
import { useGetOrganizationQuery } from 'service/organization';
import { useAuth } from 'contexts/AuthContext';

function Sidebar() {
  // useLocation hook to get the current location of the page
  // this is used to determine which sidebar option is selected using the URL pathname
  const { pathname } = useLocation();

  const sidebarOptions = [
    {
      name: 'Threats',
      urlPath: '/',
      selectedIcon: HomeSelected,
      icon: Home,
    },
    {
      name: 'Analytics',
      urlPath: '/analytics',
      selectedIcon: AnalyticsSelected,
      icon: Analytics,
    },
    {
      name: 'Account Monitoring',
      urlPath: '/users',
      selectedIcon: UsersSelected,
      icon: Users,
    },
    // {
    //   name: 'Policies',
    //   urlPath: '/policies',
    //   selectedIcon: PolicySelected,
    //   icon: Policy,
    // },
    {
      name: 'Remediation Logs',
      urlPath: '/remediation',
      selectedIcon: LogsSelected,
      icon: Logs,
    },
  ];

  const [OrgId] = useOrgId();

  const { data } = useGetOrganizationQuery(OrgId);

  const { superadmin } = useAuth();

  return (
    // the sidebar component is divided into 3 sections
    // the first section is the logo and name of the enterprise
    // the second section is the sidebar options which are displayed as buttons and are scrollable
    // the third section is the settings button and ravenmail logo
    <div className="bg-white h-full flex flex-col justify-between border-r-light border-border-primary">
      <div className="p-6">
        <div className="flex items-center gap-2 px-2">
          <div className="rounded-2xl max-w-44">
            {data?.logo ? (
              <img src={data?.logo} alt={data?.displayName} className="w-full h-full" />
            ) : (
              <span className="uppercase text-base w-full text-center break-words">
                {data?.displayName}
              </span>
            )}
          </div>
          {/* <span className="text-light-grey text-base">M2P Fintech</span> */}
        </div>
        <Divider customCss="my-4 border-border-light border-border-primary" />
      </div>
      <div className="flex-1 px-6 flex flex-col gap-4">
        {
          // the sidebar options are displayed as links and are scrollable
          sidebarOptions.map((option) => (
            <Link
              key={option.name}
              to={option.urlPath}
              className={`flex items-center gap-4 w-full rounded-md px-4 py-3 ${pathname === option.urlPath ? 'bg-sidebar-select-bg' : ''} hover:bg-sidebar-select-bg`}
            >
              {pathname === option.urlPath ? (
                <option.selectedIcon className="w-4 h-4" />
              ) : (
                <option.icon className="w-4 h-4" />
              )}
              <span
                className={`${option.urlPath === pathname ? 'text-black' : 'text-light-grey'} text-sm`}
              >
                {option.name}
              </span>
            </Link>
          ))
        }
      </div>
      <div className="w-full px-6">
        {/* using ternary operator instead of the "&&" as it ends up displaying "0" on the screen */}
        {superadmin ? (
          <Link
            to="/settings"
            className={`flex items-center gap-2 w-full rounded-md px-4 py-3 ${pathname === '/settings' ? 'bg-sidebar-select-bg' : ''} hover:bg-sidebar-select-bg`}
          >
            {pathname === '/settings' ? (
              <SettingsSelected className="w-4 h-4" />
            ) : (
              <IconSettings className="w-4 h-4" />
            )}
            <span className="text-light-grey text-sm">Settings</span>
          </Link>
        ) : null}
        <Divider customCss="my-4 border-border-light border-border-primary" />
        <div className="flex items-center justify-between pb-6 pt-2">
          <div className="flex items-center gap-2">
            <RavenGrayLogo className="w-5 h-5" />
            <span className="text-light-grey text-sm">
              <strong>Raven</strong>Mail
            </span>
          </div>
          <span className="bg-light-grey text-white rounded-md text-2xs w-fit px-1 py-0.5">
            BETA
          </span>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
