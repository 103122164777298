import Logo from 'assets/logo/logo-with-name.png';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { useAuth } from 'contexts/AuthContext';
import { ConsentStatusTypes } from 'enums/consentStatusTypes';
import { useToast } from 'hooks/toast';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyOnboardOrgQuery } from 'service/onboardingApi';
import GoogleOnboarding from 'pages/Onboarding/google';
import MicrosoftOnboarding from 'pages/Onboarding/microsoft';

function Onboarding(): JSX.Element {
  const { onboardingInfo, setOnboardingStatus, orgId } = useAuth();
  const [error, setError] = useState<string>('');
  const [consentStatus, setConsentStatus] = useState<ConsentStatusTypes>('pending');
  const [triggerOnboarding, { isLoading: isOnboarding }] = useLazyOnboardOrgQuery();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isOnboarding && consentStatus === 'success') {
      setOnboardingStatus('COMPLETED');
      showToast({
        component: <SuccessToast message="Congrats! Your onboarding was successful" />,
      });
      navigate('/users', { replace: true, state: { from: location } });
    }
  }, [location, navigate, setOnboardingStatus, isOnboarding, consentStatus, showToast]);

  const handleConsentComplete = useCallback(() => {
    setConsentStatus('success');
    triggerOnboarding({ orgId: orgId });
  }, [orgId, triggerOnboarding]);

  const handleConsentError = useCallback((errorMessage: string) => {
    setConsentStatus('failed');
    setError(errorMessage);
  }, []);

  const handleConsentCancelled = useCallback(() => {
    setConsentStatus('cancelled');
    setError('Consent was cancelled');
  }, []);

  const renderLoadingState = () => (
    <>
      <div className="self-center after:size-8 after:absolute after:bg-white after:left-1 after:rounded-full after:right-1 after:top-1 after:bottom-1 after:m-auto size-12 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-sky-500 animate-spin" />
      <div className="text-md font-normal w-72 pt-8 text-center">Setting up your dashboard</div>
    </>
  );

  const renderOnboardingContent = () => {
    if (isOnboarding || !onboardingInfo) {
      return renderLoadingState();
    }

    if (onboardingInfo?.vendor === 'gmail') {
      return (
        <GoogleOnboarding
          onConsentComplete={handleConsentComplete}
          onConsentError={handleConsentError}
          onConsentCancelled={handleConsentCancelled}
          onboardingInfo={onboardingInfo}
          isLoading={isOnboarding}
        />
      );
    }

    return (
      <MicrosoftOnboarding
        onConsentComplete={handleConsentComplete}
        onConsentError={handleConsentError}
        onConsentCancelled={handleConsentCancelled}
        onboardingInfo={onboardingInfo}
        isLoading={isOnboarding}
      />
    );
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 bg-login ">
      <div className="border-[#E1E5E8] bg-white rounded-2xl min-w-96 shadow-[0rem_.25rem_1.5rem_0rem_rgba(0,0,0,0.15)]">
        <div className="text-center p-9 pl-28 pr-28">
          <img className="h-9 inline-block" src={Logo} alt="RavenMail" />
        </div>
        <hr className="border-[#E1E5E8]" />
        <div className="min-h-80 flex justify-center align-middle">
          <div className="content-center flex justify-center flex-col align-middle items-center">
            {renderOnboardingContent()}
            <div className="h-12">
              {error && <p className="mt-2 text-center text-sm text-red-600">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
