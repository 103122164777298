interface AvatarProps {
  src?: string;
  alt?: string;
  bgColor?: string;
  textColor?: string;
  hoverBgColor?: string;
  initials?: string;
  customCssClass?: string;
  radius?: string;
}

function Avatar({
  src = '',
  alt = '',
  bgColor = '#000',
  textColor = '#606263',
  hoverBgColor = '#2A2B2B',
  initials = 'X',
  customCssClass = 'w-10 h-10 text-sm p-2.5',
  radius = 'rounded-full',
}: AvatarProps) {
  return (
    <div
      className={`${radius} flex items-center justify-center overflow-hidden cursor-pointer bg-${bgColor} hover:bg-${hoverBgColor} text-${textColor} ${customCssClass}`}
    >
      {src ? (
        <img src={src} alt={alt} className="w-full·h-full·object-cover" />
      ) : (
        <span style={{ color: textColor }}>{initials}</span>
      )}
    </div>
  );
}

export default Avatar;
