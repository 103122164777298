import { useState } from 'react';

import { Event } from 'models/events';
import Sort, { Order } from 'components/Sort';

import { ReactComponent as IconAttachment } from 'assets/icons/attachment.svg';
import { ReactComponent as IconLink } from 'assets/icons/url.svg';

import { EventRow } from './EventRow';

interface EventTableProps {
  events: Event[];
  onSort: (sorting: Order) => void;
  initialSortType: number;
}

type HeaderWithIcon = 'Attachments' | 'URL';

function EventTable({ events, onSort, initialSortType }: EventTableProps) {
  // eventHeaderColumnNames is an array of the names of the columns in the table
  const eventHeaderColumns: { name: string; width: string }[] = [
    { name: 'Subject & Content', width: 'w-1/8' },
    { name: 'Time', width: 'w-1/12' },
    { name: 'Sender', width: 'w-2/12' },
    { name: 'Recipients', width: 'w-2/12' },
    { name: 'Attachments', width: 'w-1/24' },
    { name: 'URL', width: 'w-1/24' },
    { name: 'Threat', width: 'w-1/8' },
    { name: 'Status', width: 'w-1/12' },
    { name: 'Actions', width: 'w-1/12' },
  ];

  const sortableColumns = ['Time'];

  const [sortType, setSortType] = useState<number>(initialSortType);

  const sortingOrder: Order[] = ['asc', 'desc'];

  const handleSort = () => {
    setSortType((prevSortType) => {
      const currentIndex = (prevSortType + 1) % sortingOrder.length;
      // sorting callback for the table
      onSort(sortingOrder[currentIndex]);
      return currentIndex;
    });
  };

  const getIconForHeader = (type: HeaderWithIcon) => {
    return type === 'Attachments' ? (
      <IconAttachment className="w-4 h-4" />
    ) : (
      <IconLink className="w-3 h-3" />
    );
  };

  return (
    <div className="w-full overflow-auto px-2">
      <table className="w-full bg-white overflow-auto table-fixed">
        <colgroup>
          {eventHeaderColumns.map((header) => (
            <col key={header.name} className={header.width} />
          ))}
        </colgroup>
        <thead className="text-2xs text-light-grey w-full border-b-light border-b-border-primary">
          <tr>
            {eventHeaderColumns.map((header) => {
              const isSortable = sortableColumns.includes(header.name);
              return (
                <th
                  key={header.name}
                  scope="col"
                  className={`px-6 py-3 font-normal text-start ${isSortable ? 'cursor-pointer' : ''} ${header.width}`}
                  onClick={() => {
                    if (isSortable) {
                      handleSort();
                    }
                  }}
                >
                  <div className="inline-flex items-center gap-1">
                    {['Attachments', 'URL'].includes(header.name) ? (
                      getIconForHeader(header.name as HeaderWithIcon)
                    ) : (
                      <span>{header.name}</span>
                    )}
                    {isSortable && <Sort sortOrder={sortingOrder[sortType]} />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full">
          {events.map((event) => (
            <EventRow key={event.messageId} event={event} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default EventTable;
