import { AnalysisType } from 'enums/eventStatusTypes';

interface AnalysisBadgeProps {
  type: AnalysisType;
  text: string;
  onClick?: () => void;
}

export function AnalysisBadge({ type, text, onClick }: AnalysisBadgeProps) {
  const badgeStyles = {
    tone: {
      background: 'tone-background',
      color: 'tone-text',
    },
    observation: {
      background: 'soft-sand',
      color: 'observation-text',
    },
    detection: {
      background: 'soft-red',
      color: 'critical-red',
    },
    pending: {
      background: 'soft-red',
      color: 'critical-red',
    },
    success: {
      background: 'soft-green',
      color: 'success-green',
    },
    others: {
      background: 'select-background',
      color: 'light-grey',
    },
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={`p-1 bg-${badgeStyles[type].background} flex items-center gap-1 rounded-md`}
      onClick={() => handleClick()}
    >
      {type !== 'others' ? (
        <div className={`w-1 h-1 rounded-full bg-${badgeStyles[type].color}`} />
      ) : null}
      <span className={`text-xs text-${badgeStyles[type].color}`}>{text}</span>
    </button>
  );
}
