import Card from 'components/Card';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { EventsResponse, GetEventsQueryParams } from 'models/events';
import Loader from 'components/Loader';

import EventTable from './EventTable';
import { NoEvents } from './NoEvents';

interface AllEventsProps {
  filters: GetEventsQueryParams;
  setFilters: React.Dispatch<React.SetStateAction<GetEventsQueryParams>>;
  isLoading: boolean;
  data?: EventsResponse;
}

export default function AllEvents({
  filters,
  setFilters,
  isLoading,
  data = {
    hits: [],
    total: 0,
    limit: 10,
    offset: 0,
  },
}: AllEventsProps) {
  // timePeriodSelectOptions is an array of the options in the select dropdown for selecting the time period
  const getFallback = () => {
    if (data?.hits?.length === 0) {
      return <NoEvents />;
    }
    if (isLoading) {
      return (
        <div className="w-full flex items-center justify-center">
          <Loader />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="mt-4 w-full">
      <Card width="100%">
        <div className="w-full relative">
          {!isLoading && data?.hits?.length > 0 ? (
            <EventTable
              events={data.hits}
              onSort={(sortType) => {
                setFilters({
                  ...filters,
                  sortOrder: sortType,
                });
              }}
              initialSortType={filters.sortOrder === 'asc' ? 0 : 1}
            />
          ) : (
            getFallback()
          )}
        </div>
        {data?.hits?.length > 0 && !isLoading && (
          <div className="flex justify-between px-6 py-4 items-center">
            <span className="text-xs font-light">
              Showing {data && data?.hits?.length > 0 ? data.offset + 1 : '0'} -{' '}
              {data && data?.hits.length > 0 ? data.offset + data.hits.length : '0'} of{' '}
              {data?.total} events
            </span>
            <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
              <IconArrowDown
                className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${data && data?.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                onClick={() => {
                  if (data) {
                    if (data.offset > 0) {
                      setFilters({
                        ...filters,
                        offset: data.offset - data.limit,
                      });
                    }
                  }
                }}
              />
              <IconArrowDown
                className={`w-6 h-6 -rotate-90 ${data && data.offset + data.limit < data.total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                onClick={() => {
                  if (data && data.offset + data.limit < data.total) {
                    setFilters({
                      ...filters,
                      offset: data.offset + data.limit,
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
