import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useAuth } from 'contexts/AuthContext';
import { getInitials } from 'utils/name';
import Avatar from 'components/Avatar';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import { Link } from 'react-router-dom';

interface NavbarProps {
  title: string;
  showBackButton?: boolean;
  showLogout?: boolean;
}

export function Navbar({ title, showBackButton = false, showLogout = true }: NavbarProps) {
  const { name, logout } = useAuth();

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {showBackButton && (
            <div className="flex justify-center items-center w-6 h-6 rounded-full border-light border-border-primary">
              <Link to="/" className="w-full h-full">
                <IconArrowDown className="rotate-90 w-full h-full" />
              </Link>
            </div>
          )}
          <h2 className="text-2xl">{title}</h2>
        </div>
        {showLogout && (
          <div className="flex gap-8 items-center">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="text-end" asChild>
                <div>
                  <Avatar
                    initials={getInitials(name, 2)} // a placeholder for the initials
                    bgColor="soft-blue"
                    textColor="avatar-blue"
                  />
                </div>
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <div className="w-full">
                  <DropdownMenu.Content
                    className="z-20 data-[state=open]:outline-none w-full animate-fade-in bg-white rounded-md flex flex-col text-xs p-2 shadow-custom"
                    side="left"
                  >
                    <DropdownMenu.Item
                      className="px-3 py-1.5 hover:bg-select-background hover:text-red-500 text-light-grey bg-transparent cursor-pointer outline-none"
                      onClick={() => logout()}
                    >
                      Logout
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </div>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
        )}
      </div>
      <hr className="my-5" />
    </>
  );
}
