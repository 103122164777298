import { ReactComponent as IconArrow } from 'assets/icons/metric-arrow.svg';

interface DeviationBadgeProps {
  deviation: number;
}

export function DeviationBadge({ deviation }: DeviationBadgeProps) {
  if (deviation === 0) {
    return null;
  }

  return (
    <button
      className={`p-1 flex items-center gap-1  ${deviation > 0 ? 'bg-soft-red' : 'bg-soft-green'} rounded-sm`}
      type="button"
    >
      <IconArrow
        className={`${deviation > 0 ? 'fill-error-text' : 'rotate-180 fill-success-green'} w-3 h-3`}
      />
      <span className="text-xs">{Math.abs(deviation)} %</span>
    </button>
  );
}
