import { createBrowserRouter } from 'react-router-dom';

import Home from 'pages/Home';
import Users from 'pages/Users';
import Settings from 'pages/Settings';

import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Onboarding from 'pages/Onboarding';
import Remediation from 'pages/Remediation';
import Analytics from 'pages/Analytics';
import Policies from 'pages/Policies';

import RootLayout from 'components/Layouts';

import ProtectedRoute from './ProtectedRoutes';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/onboarding',
    element: (
      <ProtectedRoute>
        <Onboarding />
      </ProtectedRoute>
    ),
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <RootLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        element: <Users />,
        path: '/users',
      },
      {
        element: <Remediation />,
        path: '/remediation',
      },
      {
        element: <Settings />,
        path: '/settings',
      },
      {
        element: <Analytics />,
        path: '/analytics',
      },
      {
        element: <Policies />,
        path: '/policies',
      },
    ],
  },
]);

export default router;
