import { useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import * as Checkbox from '@radix-ui/react-checkbox';

import { Event } from 'models/events';

import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';
import { ReactComponent as IconCross } from 'assets/icons/cross-close.svg';

import Loader from 'components/Loader';

import { useUndoRemediationMutation } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ActionToast } from 'components/Toasts/ActionToast';
import { UNDO_MOVE_TO_JUNK, UNDO_QUARANTINE_MESSAGE } from 'constants/remediationActions';
import { Analysis } from 'components/EmailAttributes/Analysis';

interface RemediateOverviewProps {
  event: Event;
  open: boolean;
  action: string;
  onClose: () => void;
}

export function RemediateOverview({
  open,
  onClose,
  event,
  action: actionOnEvent,
}: RemediateOverviewProps): JSX.Element {
  const { showToast } = useToast();

  const [checkedStateOfActions, setCheckedStateOfActions] = useState({
    moveToInbox: false,
  });

  const [loading, setLoading] = useState(false);

  const handleToggle = (action: 'moveToInbox') => {
    setCheckedStateOfActions((prevState) => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const [undoRemediation] = useUndoRemediationMutation();

  const [OrgId] = useOrgId();

  const handleUndoRemediate = async (e: React.MouseEvent) => {
    if (!checkedStateOfActions.moveToInbox || loading) {
      return;
    }

    e.stopPropagation();
    setLoading(true);

    try {
      await undoRemediation({
        orgId: OrgId,
        messageId: event.messageId,
        action: actionOnEvent === 'MOVE_TO_JUNK' ? UNDO_MOVE_TO_JUNK : UNDO_QUARANTINE_MESSAGE,
      }).unwrap();

      showToast({
        component: (
          <ActionToast description="Moved message to 'Inbox'" showUndo={false} showAvatar={false} />
        ),
      });
    } catch {
      showToast({
        component: (
          <ErrorToast message='Failed to move message to "Inbox", please try again later' />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4 "
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full max-h-overview-modal bg-white rounded-lg flex justify-center">
            <Analysis event={event} showFeedback={false} />
            <div className="w-1/3 border-l-light border-border-primary p-8 flex flex-col justify-between gap-8">
              <div className="flex flex-col gap-8">
                <span className="text-lg text-light-grey">Options for Undoing Remediations</span>
                <div className="w-full flex justify-between gap-4">
                  <div className="flex flex-col gap-1">
                    <span className="text-xs">Undo Log Out Recipient & Reset Password</span>
                    <span className="text-2xs text-light-grey">
                      Recipient will continue to be signed out of all active sessions
                    </span>
                  </div>
                  <div className="w-6 h-4 flex items-center justify-center rounded bg-border-primary">
                    <IconCross className="w-2 h-2 cursor-not-allowed" />
                  </div>
                </div>

                {event.status !== 'Pending' ? (
                  <div className="w-full flex justify-between gap-4">
                    <button
                      className="flex flex-col gap-1 cursor-pointer"
                      onClick={() => handleToggle('moveToInbox')}
                      type="button"
                    >
                      <span className="text-xs">Move mail to inbox from Remediated List</span>
                      <span className="text-2xs text-light-grey text-start">
                        This email will be moved back to the user’s inbox from the remediated list.
                      </span>
                    </button>
                    <Checkbox.Root
                      className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                      checked={checkedStateOfActions.moveToInbox}
                      onClick={() => handleToggle('moveToInbox')}
                    >
                      <Checkbox.Indicator>
                        <IconCheck />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                  </div>
                ) : null}
              </div>
              <button
                className={`w-full ${checkedStateOfActions.moveToInbox ? 'bg-black' : 'bg-black/20'} rounded-full text-white p-4 text-center text-base ${checkedStateOfActions.moveToInbox ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                type="button"
                onClick={handleUndoRemediate}
              >
                {loading ? <Loader color="white" /> : 'Undo Remediation'}
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
