import { useState } from 'react';
import { dateUtils } from 'utils/time';

interface DateFilterProps {
  onClick: (from: string, to: string) => void;
}
export default function DateFilter({ onClick }: DateFilterProps) {
  const dateOptions: { label: string; value: number }[] = [
    {
      label: 'Week',
      value: 7,
    },
    {
      label: 'Month',
      value: 30,
    },
    {
      label: 'Year',
      value: 365,
    },
  ];

  // Default to 7 days
  const [selectedOption, setSelectedOption] = useState(7);

  const handleClick = (value: number) => {
    setSelectedOption(value);

    const now = new Date();
    const from = dateUtils
      .subtract(now.toDateString(), value - 1, 'd')
      .toDate()
      .toISOString();

    onClick(from, now.toISOString());
  };

  return (
    <div className="border-light border-border-primary p-1 flex justify-between items-center gap-2 rounded-lg bg-white text-sm">
      {dateOptions.map((option) => (
        <button
          key={option.label}
          className={`${option.value === selectedOption ? 'bg-black text-white' : 'text-light-grey'} rounded-lg py-2.5 px-4`}
          onClick={() => handleClick(option.value)}
          type="button"
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
