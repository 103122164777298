import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_THREAT_CATEGORY_STATS, GET_THREATS } from 'constants/apiUrls';
import { GetThreatsFilter, ThreatStatsResult } from 'models/threats';
import { Metric } from 'models/metric';

import { baseQuery } from './baseQuery';

export const threatsApi = createApi({
  reducerPath: 'threatsApi',
  baseQuery: baseQuery,
  tagTypes: ['THREATS'],
  endpoints: (builder) => ({
    getThreatStats: builder.query<ThreatStatsResult, GetThreatsFilter>({
      query: (filter) => {
        const params = new URLSearchParams();

        if (filter.from) params.append('from', filter.from);
        if (filter.to) params.append('to', filter.to);
        if (filter.threatCategory) params.append('category', filter.threatCategory);

        return `${GET_THREATS(filter.orgId)}?${params.toString()}`;
      },
    }),
    getThreatCategoryStats: builder.query<Metric[], { orgId: string; from: string; to: string }>({
      query: (filter) => {
        const params = new URLSearchParams();

        if (filter.from) params.append('from', filter.from);
        if (filter.to) params.append('to', filter.to);

        return `${GET_THREAT_CATEGORY_STATS(filter.orgId)}?${params.toString()}`;
      },
    }),
  }),
});

export const { useGetThreatStatsQuery, useGetThreatCategoryStatsQuery } = threatsApi;
