import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_ORGANIZATION } from 'constants/apiUrls';
import { Organization } from 'models/organization';
import { baseQuery } from './baseQuery';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: baseQuery,
  tagTypes: ['ORGANIZATION'],
  endpoints: (builder) => ({
    getOrganization: builder.query<Organization, string>({
      query: (orgId: string) => GET_ORGANIZATION(orgId),
      providesTags: ['ORGANIZATION'],
    }),
  }),
});

export const { useGetOrganizationQuery } = organizationApi;
