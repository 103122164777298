import { useGetStatisticsQuery } from 'service/statisticsApi';

import { useOrgId } from 'hooks/useOrgId';
import flagIcon from '../../assets/icons/flags.svg';
import envelopeIcon from '../../assets/icons/icon-message-grey.svg';

import { Metrics } from './metrics';

type EmailMetrics = 'flaggedMessages' | 'messages';

type Metric = {
  key: EmailMetrics;
  icon: string;
  name: string;
};

interface StatisticsProps {
  from: string;
  to: string;
}
export default function Statistics({ from, to }: StatisticsProps) {
  /*
  metricsList is an array of objects that contains the key, icon, and name of each metric
  which are static properties of the Metrics component 
  format of each object is {key: EmailMetrics, icon: string, name: string}

  key: used to map the metric value to the corresponding key in the metricStats object
  icon: used to display the icon of the metric
  name: used to display the name of the metric
  */

  const metricsList: Metric[] = [
    {
      key: 'messages',
      icon: envelopeIcon,
      name: 'Messages',
    },
    {
      key: 'flaggedMessages',
      icon: flagIcon,
      name: 'Flagged Emails',
    },
  ];
  const [OrgId] = useOrgId();

  const { data } = useGetStatisticsQuery({
    orgId: OrgId,
    from,
    to,
  });

  return (
    <div className="w-2/5 border-border-primary border-light rounded-md flex items-center bg-white">
      {metricsList?.map((metric, index) => (
        <Metrics
          icon={metric.icon}
          name={metric.name}
          value={data?.[metric.key] || 0}
          hasRightBorder={metricsList.length - 1 !== index}
        />
      ))}
    </div>
  );
}
