import React, { useState } from 'react';

import { Badge } from 'components/Badge';

import { Event } from 'models/events';

import { dateUtils } from 'utils/time';

import { ReactComponent as IconRemediation } from 'assets/icons/remediation.svg';

import { Alert } from 'components/Alert';

import { useToast } from 'hooks/toast';
import { useForceLogoutMutation } from 'service/users';
import { useGetFlaggedEventQuery, useRemediateMessageMutation } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import { WarningToast } from 'components/Toasts/WarningToast';
import { MOVE_TO_JUNK, QUARANTINE_MESSAGE } from 'constants/remediationActions';

// import { EventActionMenu } from './EventActionMenu';
import { getBadges, getInferences, getRecipientDisplayName } from './data';
import { EventOverview } from './EventOverview';
import { EventRemediation } from './EventRemediation';

export function renderRecepients(event: Event) {
  if (event?.recipients?.length) {
    const totalCount =
      (event.recipients?.length || 0) + (event?.cc?.length || 0) + (event?.bcc?.length || 0);
    if (totalCount === 1) {
      return (
        <>
          <span className="text-xs break-words">{event.recipients[0].name}</span>
          <span className="text-xs text-light-grey break-words">{event.recipients[0].email}</span>
        </>
      );
    }

    return <span className="text-xs">{totalCount} Recipients</span>;
  }

  return <span className="text-xs text-light-grey"> No Recipients </span>;
}

export const generateActionDescription = (
  checkedStatus: Record<'logout' | 'quarantine', boolean>,
  actionResult: Record<'logout' | 'quarantine', boolean>
) => {
  if (checkedStatus.logout && checkedStatus.quarantine) {
    if (actionResult.logout && actionResult.quarantine) {
      return 'Email quarantined and recepient logged out successfully';
    }

    const toastDescription = 'Action partially completed.';
    if (actionResult.logout) {
      return `${toastDescription} Recepient logged out successfully and failed to quarantine email`;
    }

    if (actionResult.quarantine) {
      return `${toastDescription} Email quarantined successfully and failed to logout recepient`;
    }
  }

  if (checkedStatus.logout) {
    if (actionResult.logout) {
      return 'Recepient logged out successfully';
    }

    return 'Recepient logout failed';
  }

  if (checkedStatus.quarantine) {
    if (actionResult.quarantine) {
      return `Email Remediated successfully`;
    }

    return 'Email Remediation failed';
  }

  return 'Something went wrong';
};

export function EventRow({ event }: { event: Event }) {
  const [openOverview, setOpenOverview] = useState(false);

  const { showToast } = useToast();

  const [alertOpen, setAlertOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [forceLogoutUser] = useForceLogoutMutation();

  const [remediateMessage] = useRemediateMessageMutation();

  const [OrgId] = useOrgId();

  const handleRemediateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (event.status !== 'Pending') {
      return;
    }
    setAlertOpen(true);
  };

  const [checkedStateOfActions, setCheckedStateOfActions] = useState({
    logout: false,
    quarantine: false,
  });

  const handleToggle = (action: 'logout' | 'quarantine') => {
    setCheckedStateOfActions((prevState) => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const [remediationAction, setRemediationAction] = useState<'junk' | 'quarantine' | ''>('');

  const handleRemediateCheck = (remediateAction: 'junk' | 'quarantine') => {
    setRemediationAction(checkedStateOfActions.quarantine === true ? '' : remediateAction);
    handleToggle('quarantine');
  };

  const handleRemediateConfirm = async () => {
    if (!(checkedStateOfActions.logout || checkedStateOfActions.quarantine)) {
      showToast({
        component: <WarningToast message="Please select at least one action" />,
      });
      return;
    }

    setLoading(true);

    const actionResult = {
      logout: false,
      quarantine: false,
    };

    try {
      if (checkedStateOfActions.logout) {
        await forceLogoutUser({
          orgId: OrgId,
          employeeId: event.employeeId,
        }).unwrap();

        actionResult.logout = true;
      }

      if (checkedStateOfActions.quarantine) {
        await remediateMessage({
          orgId: OrgId,
          messageId: event.messageId,
          folder: remediationAction === 'junk' ? MOVE_TO_JUNK : QUARANTINE_MESSAGE,
        }).unwrap();

        actionResult.quarantine = true;
      }

      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } catch {
      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } finally {
      setAlertOpen(false);
      setLoading(false);
    }
  };

  const { data: eventDetails, isLoading } = useGetFlaggedEventQuery(
    { messageId: event.messageId, organizationId: OrgId },
    {
      skip: !openOverview,
    }
  );

  return (
    <>
      <tr
        className="border-b align-top cursor-pointer"
        onClick={() => setOpenOverview(true)}
        data-message-id={event.messageId}
      >
        <td title={event.subject} className="text-start px-6 py-3">
          <p className="text-black text-xs max-w-full text-ellipsis line-clamp-2">
            {event.subject}
          </p>
        </td>
        <td className="text-start px-6 py-3 text-black text-xs">
          <div>{dateUtils.format(event?.time, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(event.time, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="text-start px-6 py-3">
          <p
            title={event.sender.email}
            className={`w-fit text-left px-2 py-0.5 text-xs text-black ${getInferences('sender', event).length ? 'bg-soft-red border-soft-red-border border-light rounded-sm' : ''} break-words max-w-full text-ellipsis line-clamp-1`}
          >
            {event.sender.email}
          </p>
        </td>
        <td className="text-start px-6 py-3">
          <p
            title={getRecipientDisplayName(event)}
            className={`w-fit text-left px-2 py-0.5 text-xs text-black ${getInferences('recipients', event).length ? 'bg-soft-red border-soft-red-border border-light rounded-sm' : ''} break-words max-w-full text-ellipsis line-clamp-1`}
          >
            {getRecipientDisplayName(event)}
          </p>
        </td>
        <td className="text-start px-6 py-3 text-black text-xs">
          <button
            className={`rounded-md border-light border-border-primary ${event?.attachments?.length ? 'text-black' : 'text-light-grey'} ${event?.attachments?.filter((attachment) => attachment.suspicious)?.length ? 'bg-soft-red' : 'bg-soft-gray'} py-0.5 px-1.5`}
            type="button"
          >
            {event?.attachments?.length || '0'}
          </button>
        </td>
        <td className="text-start px-6 py-3 text-black text-xs">
          <button
            className={`rounded-md border-light border-border-primary ${event?.links?.length ? 'text-black' : 'text-light-grey'} ${event?.links?.filter((link) => link.suspicious)?.length ? 'bg-soft-red' : 'bg-soft-gray'} py-0.5 px-1.5`}
            type="button"
          >
            {event?.links?.length || '0'}
          </button>
        </td>
        <td className="text-start px-6 py-3 text-black text-xs flex flex-wrap gap-2">
          {getBadges(event)}
        </td>
        <td className="text-start px-6 py-3 text-xs">
          <Badge
            text={event.status}
            bgColor={event.status === 'Remediated' ? 'soft-green' : 'soft-red'}
            borderColor={event.status === 'Remediated' ? 'soft-green-border' : 'soft-red-border'}
            textColor={event.status === 'Remediated' ? 'success-green' : 'error-text'}
            borderRadius="rounded-lg"
            textCase="capitalize"
            textSize="xs"
          />
        </td>
        <td className="text-center px-6 py-3 text-black text-xs cursor-pointer">
          <button
            type="button"
            className={`bg-${event.status === 'Pending' ? 'sidebar-background' : 'success-green'} px-3 py-1.5 rounded-lg text-white`}
            onClick={handleRemediateClick}
          >
            {event.status === 'Pending' ? 'Remediate' : 'Remediated'}
          </button>
        </td>
      </tr>
      {openOverview ? (
        <EventOverview
          event={eventDetails}
          open={openOverview}
          onClose={() => setOpenOverview(false)}
          generateActionDescription={generateActionDescription}
          isLoading={isLoading}
        />
      ) : null}
      <Alert
        open={alertOpen}
        cancelText="Cancel"
        confirmText="Confirm"
        content={
          <EventRemediation
            checkedStateOfActions={checkedStateOfActions}
            handleToggle={handleToggle}
            handleRemediateCheck={handleRemediateCheck}
            remediationAction={remediationAction}
          />
        }
        onConfirm={() => handleRemediateConfirm()}
        onCancel={() => setAlertOpen(false)}
        message="Are you sure you want to remediate this email? The following actions will be performed"
        icon={<IconRemediation />}
        loading={loading}
      />
    </>
  );
}
