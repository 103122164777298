interface BadgeProps {
  icon?: React.ReactNode;
  text: string;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  borderRadius?: string;
  customCssClass?: string;
  onClick?: () => void;
  textAlign?: string;
  textCase?: string;
  textSize?: string;
}

export function Badge({
  text,
  bgColor,
  borderColor,
  textColor,
  borderRadius = 'rounded-full',
  customCssClass = '',
  icon,
  textAlign = 'center',
  onClick,
  textCase = 'uppercase',
  textSize = 'xs',
}: BadgeProps) {
  return (
    <button
      type="button"
      className={`px-2 py-0.5 gap-1 ${borderRadius} border border-${borderColor} bg-${bgColor} ${customCssClass}`}
      onClick={onClick}
    >
      {icon}
      <span className={`text-${textAlign} ${textCase} text-${textColor} text-${textSize}`}>
        {text}
      </span>
    </button>
  );
}
